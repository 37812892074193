






























































import { API } from "@/shared/api";
import { connectDto } from "@/shared/dtos/connectDto";
import { ssmHttpService } from "@/shared/services/http-service";
import { MessageService } from "@/shared/services/message-service";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class instalacion extends Vue {
  private titulo_proyecto = "";
  private connect: connectDto = new connectDto();
  public created() {
    this.titulo_proyecto = process.env.VUE_APP_TITLE as string;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        ssmHttpService
          .post(API.Connect, this.connect.toJson(), true, false, true)
          .then(() =>
            MessageService.toastsuccesful(
              "Se ha guardado la conexión a la base de datos."
            )
          );
      }
    });
  }
}
